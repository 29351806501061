import {Box, useTheme} from '@mui/material';
import {useTranslation} from 'next-i18next';
import {CampaignDetails} from '../../types';
import {getLocaleLabels} from '../../utils/lang';
import {TabTitle} from '../common/text';
import Packs from './packs';
import useParser from '../../utils/htmlToReact';

const OPTIMIZE_DESCRIPTION_IMAGES = true;

type DescriptionProps = {
    data: CampaignDetails;
    enabled: boolean;
    onAddSuccess: () => void;
};

export default function Description(props: DescriptionProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {t, i18n} = useTranslation('common');
    const parse = useParser({optimizeImages: OPTIMIZE_DESCRIPTION_IMAGES});

    const labels = getLocaleLabels(props.data?.labels, i18n.language);

    const className = `dangerous-html sun-editor-editable ${isDark ? 'dark' : 'light'}`;

    let descriptionContent = null;
    if (labels?.description) {
        descriptionContent = parse(labels?.description);
    }

    return (
        <>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box className={className}>
                    {/* <div dangerouslySetInnerHTML={{__html: labels?.description}}></div> */}
                    {descriptionContent}
                    {Boolean(labels?.about) && (
                        <>
                            <TabTitle>{t('title.about')}</TabTitle>
                            <div dangerouslySetInnerHTML={{__html: labels?.about}}></div>
                        </>
                    )}
                </Box>
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    <Packs
                        data={props.data}
                        enabled={props.enabled}
                        onAddSuccess={props.onAddSuccess}
                        direction="column"
                        limit={false}
                    />
                </Box>
            </Box>
        </>
    );
}
