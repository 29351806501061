import {Box, CircularProgress, Tab, Tabs, Typography, useTheme} from '@mui/material';
import {CampaignDetails, CampaignList, Theme} from '../../types';
import {CampaignStatus, getCampaignStatus, isBasketEmpty} from '../../utils/campaign';
import {MAX_WIDTH, Page} from '../../components/common/container';
import {URL_CLIENT, URL_SERVER_INTERNAL} from '../../utils/settings';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {CAMPAIGN_GET_URL} from '../../queries/keys';
import {CampaignTitle} from '../../components/common/text';
import {Colors} from '../../components/common/colors';
import Description from '../../components/campaign/description';
import {GetStaticProps} from 'next';
import Head from 'next/head';
import Header from '../../components/campaign/header';
import Image from 'next/image';
import {ImportantButton} from '../../components/common/button';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import dynamic from 'next/dynamic';
import {getLocaleLabels} from '../../utils/lang';
import {getLocaleNews} from '../../utils/storage';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {useBasket} from '../../provider/BasketProvider';
import {useGetCampaignDetail} from '../../queries/campaignQueries';
import {useQueryClient} from 'react-query';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';

const Packs = dynamic(() => import('../../components/campaign/packs'));
const NewsFeed = dynamic(() => import('../../components/campaign/news'));
const ParticipationList = dynamic(() => import('../../components/campaign/participationList'));
const Help = dynamic(() => import('../../components/campaign/help'));

type CampaignSeoProps = {
    url: string;
    title: string;
    description: string;
    image: string;
};

function CampaignSeo(props: CampaignSeoProps) {
    const url = props.url;
    const title = props.title;
    const description = props.description;
    const image = props.image;

    const urlFr = URL_CLIENT + '/' + url;
    const urlEn = URL_CLIENT + '/en/' + url;

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:url" content={urlFr} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={title} />
            <meta property="og:image:width" content="640" />
            <meta property="og:image:height" content="360" />
            <meta property="og:ttl" content="2419200" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />
            <meta property="twitter:image:alt" content={title} />
            <meta property="twitter:url" content={urlFr} />
            <meta property="twitter:site" content="@KuneKune" />
            <link hrefLang="fr" href={urlFr} rel="canonical" />
            <link hrefLang="en" href={urlEn} rel="alternate" />
        </Head>
    );
}

export default function Campaign(props: CampaignStaticProps) {
    // console.log('props = ', props);
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const router = useRouter();
    const {i18n, t} = useTranslation('common');
    const {url, preview} = router.query;
    const value = router.query?.tab ?? 'description';
    const setValue = (newValue: string) => {
        router.replace(
            {
                pathname: router.pathname,
                query: {
                    ...router.query,
                    tab: newValue,
                },
            },
            undefined,
            {shallow: true}
        );
    };
    const packRef = useRef(null);
    const aboveTabRef = useRef(null);
    const {isMobile, isTablet} = useResponsive();
    const {basket} = useBasket();
    const queryClient = useQueryClient();
    const [nbNewsClient, setNbNewsClient] = useState<number>(0);

    useEffect(() => {
        if ((isMobile || isTablet) && value == 'pack') {
            setValue('description');
        }
    }, [isMobile, isTablet, value]);

    const goToPack = useCallback(() => {
        if (!isMobile && !isTablet) {
            setValue('pack');
        }
        if (packRef.current) {
            packRef.current.scrollIntoView();
        } else {
            setTimeout(() => {
                packRef.current?.scrollIntoView();
            }, 500);
        }
    }, [isMobile, isTablet]);

    useEffect(() => {
        if (router.asPath && router.asPath.indexOf('#pack') != -1) {
            goToPack();
        }
    }, [router.asPath, goToPack]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (aboveTabRef.current) {
            aboveTabRef.current.scrollIntoView();
        }
    };

    if (props.data?.url) {
        queryClient.setQueryData<CampaignDetails>(
            CAMPAIGN_GET_URL + ':' + props.data?.url,
            props.data
        );
    }

    const {
        data: campaignData,
        refetch,
        dataUpdatedAt,
    } = useGetCampaignDetail(Boolean(url), url as string, props.data);

    const campaign = useMemo(() => {
        if (!props.data) {
            return campaignData;
        }

        if (props.updatedAt > dataUpdatedAt) {
            return props.data;
        } else {
            return campaignData;
        }
    }, [props.data, props.updatedAt, campaignData, dataUpdatedAt]);

    useEffect(() => {
        if (preview == '1' && refetch) {
            setTimeout(refetch, 1000);
        }
    }, [preview, refetch]);

    useEffect(() => {
        const localNews = getLocaleNews();
        const nbNews = campaign?.news?.length ?? 0;
        const displayNews = nbNews - localNews;
        setNbNewsClient(displayNews);
    }, [campaign, value]);

    async function goToNextStep() {
        router.push('/' + campaign.url + '/basket');
    }

    const basketIsEmpty = isBasketEmpty(basket?.items, campaignData);

    async function preorder() {
        if (basketIsEmpty) {
            goToPack();
        } else {
            goToNextStep();
        }
    }

    const status = getCampaignStatus(campaign);
    // console.log('STATUS = ', status);
    const enabled = status == CampaignStatus.PENDING || status == CampaignStatus.EXTRA;

    const labels = getLocaleLabels(campaign?.labels, i18n.language);

    const tabFontSize = {xs: 12, md: 14};
    const tabPadding = {xs: '5px', md: '15px'};

    // SEO
    const seoTitle = `Kunekune | ${labels?.seo_title ?? labels?.title}`;
    const seoDescription = labels?.seo_description ?? labels?.subtitle;
    const image = labels?.cover_url;

    if (router.isFallback || !campaign) {
        return (
            <>
                <Page>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        marginTop="200px"
                    >
                        <CircularProgress />
                    </Box>
                </Page>
            </>
        );
    }

    return (
        <>
            <Box sx={{paddingTop: 6, paddingBottom: 10, position: 'relative'}}>
                {labels?.background_cover_url && (
                    <Box
                        sx={{
                            position: 'absolute',
                            overflow: 'hidden',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            zIndex: -1,
                        }}
                    >
                        <Image
                            priority
                            src={labels?.background_cover_url}
                            alt="Campaign background image"
                            fill={true}
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    </Box>
                )}
                <CampaignSeo
                    url={url as string}
                    title={seoTitle}
                    description={seoDescription}
                    image={image}
                />
                <CampaignTitle
                    overAnImage={Boolean(labels?.background_cover_url)}
                    overDarkImage={campaign.is_background_cover_url_dark}
                >
                    {labels?.title}
                </CampaignTitle>
                <Header
                    showContributions={() => setValue('participation')}
                    data={campaign}
                    goToPack={goToPack}
                    enabled={enabled}
                    goToNextStep={goToNextStep}
                />
            </Box>
            <div
                ref={aboveTabRef}
                style={{
                    scrollMarginTop: '100px',
                    position: 'sticky',
                    top: 50,
                    zIndex: 2,
                }}
            >
                <Box
                    display="flex"
                    flexDirection={'row'}
                    justifyContent="center"
                    sx={{
                        backgroundColor: isDark ? '#000000DD' : '#f0f0f0DD',
                        backdropFilter: 'blur(20px)',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="secondary tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: campaign?.theme?.background_color,
                            },
                        }}
                        sx={{
                            flex: '1',
                            marginLeft: '10px',
                            flexDirection: 'row',
                            '& div.MuiTabs-flexContainer': {
                                flex: '1',
                                justifyContent: 'space-between',
                            },
                            '& button.MuiTab-root': {
                                color: isDark ? Colors.light : Colors.dark,
                                minWidth: 'auto',
                            },
                            '& button:hover': {
                                color: campaign?.theme?.background_color,
                            },
                            '& button:focus': {
                                color: campaign?.theme?.background_color,
                            },
                            '& button:focus .test': {
                                color: campaign?.theme?.background_color,
                            },
                            '& button.Mui-selected': {
                                color: campaign?.theme?.background_color,
                            },

                            justifyContent: {xs: 'center', md: 'space-evenly'},
                            maxWidth: MAX_WIDTH,
                        }}
                    >
                        <Tab
                            value="description"
                            label={t('title.project')}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: tabFontSize,
                                padding: tabPadding,
                            }}
                        />
                        {!isMobile && !isTablet && (
                            <Tab
                                value="pack"
                                label={t('title.pack')}
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: tabFontSize,
                                    padding: tabPadding,
                                }}
                            />
                        )}
                        <Tab
                            value="participation"
                            label={t('title.participations')}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: tabFontSize,
                                padding: tabPadding,
                            }}
                        />
                        {campaign?.news?.length > 0 && (
                            <Tab
                                value="news"
                                label={
                                    <MyBadge
                                        theme={campaign?.theme}
                                        title={t('title.news')}
                                        content={nbNewsClient}
                                    />
                                }
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: tabFontSize,
                                    padding: tabPadding,
                                }}
                            />
                        )}
                        <Tab
                            value="help"
                            label={t('title.help')}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: tabFontSize,
                                padding: tabPadding,
                            }}
                        />
                        <Tab
                            value="toto"
                            label={<></>}
                            sx={{
                                padding: '0px',
                                width: '0px',
                            }}
                        />
                    </Tabs>
                    {!isMobile && (
                        <ImportantButton
                            onClick={preorder}
                            disabled={!enabled}
                            sx={{
                                marginRight: '10px',
                                paddingLeft: '40px',
                                paddingRight: '40px',
                                backgroundColor: campaign?.theme?.background_color,
                                '&:hover': {
                                    backgroundColor: campaign?.theme?.hover_color,
                                    color: 'white !important',
                                },
                                alignSelf: 'center',
                                '&.Mui-disabled': {
                                    backgroundColor: Colors.grey,
                                },
                            }}
                            startIcon={basketIsEmpty ? null : <ShoppingBagOutlinedIcon />}
                        >
                            {basketIsEmpty ? t('action.preorder') : t('action.buy')}
                        </ImportantButton>
                    )}
                </Box>
            </div>
            <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                <ImportantButton
                    onClick={preorder}
                    disabled={!enabled}
                    sx={{
                        position: 'fixed',
                        bottom: '0px',
                        left: '0px',
                        width: '100%',
                        backgroundColor: campaign?.theme?.background_color,
                        '&:hover': {
                            backgroundColor: campaign?.theme?.hover_color,
                        },
                        borderRadius: '0px',
                        zIndex: 2,
                        '&.Mui-disabled': {
                            backgroundColor: Colors.grey,
                        },
                    }}
                    startIcon={basketIsEmpty ? null : <ShoppingBagOutlinedIcon />}
                >
                    {basketIsEmpty ? t('action.preorder') : t('action.buy')}
                </ImportantButton>
            </Box>
            <Page>
                <div hidden={value !== 'pack'}>
                    <div ref={packRef} style={{scrollMarginTop: 100}}>
                        <Packs
                            data={campaign}
                            enabled={enabled}
                            onAddSuccess={goToNextStep}
                            direction="row"
                            limit={true}
                        />
                    </div>
                </div>
                <div hidden={value !== 'description'}>
                    <Description
                        data={campaign}
                        enabled={enabled}
                        onAddSuccess={goToNextStep}
                    />
                </div>
                <div hidden={value !== 'news'}>
                    <NewsFeed
                        news={campaign?.news}
                        networks={campaign?.networks}
                        author={campaign?.author}
                        theme={campaign?.theme}
                    />
                </div>
                <div hidden={value !== 'participation'}>
                    <ParticipationList
                        campaignId={campaign?.campaign_id}
                        theme={campaign?.theme}
                    />
                </div>
                <div hidden={value !== 'help'}>
                    <Help
                        data={campaign}
                        campaignId={campaign?.campaign_id}
                        theme={campaign?.theme}
                    />
                </div>
                <Box
                    ref={packRef}
                    sx={{
                        display: {xs: 'block', md: 'none'},
                        scrollMarginTop: 100,
                        marginBottom: 3,
                    }}
                >
                    <Packs
                        data={campaign}
                        enabled={enabled}
                        onAddSuccess={goToNextStep}
                        direction="row"
                        limit={true}
                    />
                </Box>
            </Page>
        </>
    );
}

type MyBadgeProps = {
    theme: Theme;
    title: string;
    content: number;
};

function MyBadge(props: MyBadgeProps) {
    const tabFontSize = {xs: 12, md: 14};

    const bgColor = props.theme?.background_color ?? Colors.first;

    return (
        <Box display="flex" flexDirection="row">
            <Typography
                className="test"
                sx={{
                    // color: 'black',
                    fontSize: tabFontSize,
                    fontWeight: '700',
                    '&:hover': {
                        color: bgColor,
                    },
                }}
            >
                {props.title}
            </Typography>
            {props.content > 0 && (
                <Typography
                    sx={{
                        color: 'white',
                        backgroundColor: bgColor,
                        borderRadius: '15px',
                        minWidth: {xs: '15px', md: '20px'},
                        height: {xs: '15px', md: '20px'},
                        paddingLeft: '3px',
                        paddingRight: '3px',
                        marginLeft: '5px',
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        fontSize: {xs: '10px', md: '12px'},
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    {props.content}
                </Typography>
            )}
        </Box>
    );
}

type CampaignStaticProps = {
    data: CampaignDetails;
    updatedAt: number;
};

export const getStaticProps: GetStaticProps<CampaignStaticProps> = async (context) => {
    const translation = await serverSideTranslations(context.locale, ['common']);
    try {
        const campaign = context.params.url as string;

        console.log('getStaticProps::campaign = ', campaign);

        let url = URL_SERVER_INTERNAL + '/campaign/detail?url=' + campaign;
        let requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const result = await fetch(url, requestOptions);
        const json = await result.json();

        console.log('getStaticProps::json = ', json);
        console.log(
            'getStaticProps::json.title = ',
            json.labels?.find((l) => l.lang == 'fr')?.title
        );

        return {
            props: {
                data: json,
                updatedAt: Date.now(),
                ...translation,
            },
            revalidate: 60,
        };
    } catch (e) {
        console.log('getServerSideProps::error campaign detail', e);
        return {
            props: {
                data: null,
                updatedAt: Date.now(),
                ...translation,
            },
            revalidate: 60,
        };
    }
};

export async function getStaticPaths({locales}) {
    try {
        let url = URL_SERVER_INTERNAL + '/campaign/list';
        let requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const result = await fetch(url, requestOptions);
        const urls = await result.json();

        console.log('in index tsx, urls', urls);
        const paths = urls
            .map((author: CampaignList) =>
                locales.map((locale) => ({
                    params: {url: author.url},
                    locale,
                }))
            )
            .flat();

        console.log('paths = ', paths);

        return {
            paths: paths,
            fallback: true,
        };
    } catch (e) {
        console.log('getStaticPaths::error ', e);

        return {
            paths: [],
            fallback: true,
        };
    }
}
