import {Box} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {CampaignDetails, Pack} from '../../types';
import {TabTitle} from '../common/text';
import dynamic from 'next/dynamic';

const PackElement = dynamic(() => import('./packElement'));
const PackPopup = dynamic(() => import('./packPopup'));

type PacksProps = {
    data: CampaignDetails;
    enabled: boolean;
    limit: boolean;
    onAddSuccess: () => void;
    direction: 'row' | 'column';
};

export default function Packs(props: PacksProps) {
    const {t} = useTranslation('common');
    const [pack, setPack] = useState<Pack | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const {isMobile, isTablet} = useResponsive();
    // console.log('packs = ', props.packs);

    const packs = props.data?.packs ?? [];
    const url = props.data?.url;

    function openPack(packId: string) {
        setOpen(true);
        const selection = packs.find((f) => f.pack_id == packId);
        setPack(selection);
    }

    const filteredPacks = packs.filter((p) => !p.is_bonus_pack);
    const orderedPack = filteredPacks.sort((a, b) => a.position - b.position);

    return (
        <>
            {props.direction == 'row' && <TabTitle id="pack">{t('title.pack')}</TabTitle>}
            <Box>
                <Box
                    display={'flex'}
                    flexDirection={props.direction}
                    flexWrap={isMobile || isTablet ? 'nowrap' : 'wrap'}
                    justifyContent={isMobile || isTablet ? 'flex-start' : 'space-evenly'}
                    overflow={'auto'}
                    sx={{paddingTop: '20px', paddingBottom: '20px'}}
                >
                    {orderedPack.map((p) => (
                        <PackElement
                            pack={p}
                            key={p.position ?? 0}
                            enabled={props.enabled}
                            action={() => openPack(p.pack_id)}
                            limit={props.limit}
                            theme={props.data.theme}
                            width={300}
                            shadow={true}
                            info={true}
                        />
                    ))}
                </Box>
                {pack && (
                    <PackPopup
                        open={open}
                        setOpen={setOpen}
                        pack={pack}
                        url={url}
                        onAddSuccess={props.onAddSuccess}
                        enabled={props.enabled}
                        theme={props.data.theme}
                        suggestion={false}
                    />
                )}
            </Box>
        </>
    );
}
