import {Avatar, Box, IconButton, Typography, useTheme} from '@mui/material';
import {
    CampaignStatus,
    getCampaignStatus,
    getCampaignTimeLeft,
    isBasketEmpty,
} from '../../utils/campaign';
import {ImportantButton, MyLink} from '../common/button';
import {useEffect, useState} from 'react';

import {AuthorOverview} from '../../types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {CampaignDetails} from '../../types/models/CampaignDetails';
import {Colors} from '../common/colors';
import GroupsIcon from '@mui/icons-material/Groups';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import Image from 'next/image';
import Indicator from './indicator';
import LikeButton from './like';
import Link from 'next/link';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {RATIO_IMAGE} from '../../utils/settings';
import {Savings} from '@mui/icons-material';
import Share from '../../components/campaign/share';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import TagElement from '../../components/campaign/tag';
import {getLocaleLabels} from '../../utils/lang';
import {getYoutubeVideoId} from '../../utils/youtube';
import {useBasket} from '../../provider/BasketProvider';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';

type HeaderProps = {
    data: CampaignDetails;
    enabled: boolean;
    goToPack: () => void;
    goToNextStep: () => void;
    showContributions: () => void;
};

const MOBILE_CARD_WIDTH = 320;
const MOBILE_CARD_HEIGHT = MOBILE_CARD_WIDTH / RATIO_IMAGE;
const LAPTOP_CARD_WIDTH = 550;
const LAPTOP_CARD_HEIGHT = LAPTOP_CARD_WIDTH / RATIO_IMAGE;

export default function Header(props: HeaderProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const imgWidth = isTablet || isMobile ? 320 : 550;
    const campaign = props.data;

    const [modeVideo, setModeVideo] = useState<boolean>(false);
    const [ytp, setYtp] = useState<YT.Player | null>(null);
    const {t, i18n} = useTranslation('common');
    const router = useRouter();
    const {basket} = useBasket();

    useEffect(() => {
        function handleResize() {
            if (modeVideo) {
                const height = isMobile || isTablet ? 180 : 360;
                const width = isMobile || isTablet ? 320 : 640;
                ytp.setSize(width, height);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [modeVideo, ytp, isMobile, isTablet]);

    async function play() {
        setModeVideo(true);
        // 2. This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement('script');

        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // 3. This function creates an <iframe> (and YouTube player)
        //    after the API code downloads.
        var player;
        (window as any).onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
            player = new YT.Player('player', {
                height: imgWidth / RATIO_IMAGE,
                width: imgWidth,
                videoId: getYoutubeVideoId(labels.video_url),
                events: {
                    onReady: onPlayerReady,
                },
            });
            setYtp(player);
        };

        // 4. The API will call this function when the video player is ready.
        function onPlayerReady(event) {
            event.target.playVideo();
        }
    }

    const basketIsEmpty = isBasketEmpty(basket?.items, campaign);

    async function preorder() {
        if (basketIsEmpty) {
            props.goToPack();
        } else {
            props.goToNextStep();
        }
    }

    if (!campaign) {
        return <></>;
    }

    const labels = getLocaleLabels(campaign.labels, i18n.language);
    const picture = labels.cover_url;
    const percentage = Math.round(
        campaign.goal > 0 ? (campaign.amount / campaign.goal) * 100 : 0
    );

    // const status = getCampaignStatus(campaign);
    // console.log('STATUS = ', status);

    const timeLeft = getCampaignTimeLeft(campaign);
    // console.log('timeLeft = ', timeLeft);

    const blocDirection = {xs: 'column', xl: 'row'};

    const status = getCampaignStatus(campaign);
    // console.log('STATUS = ', status);
    const enabled = status == CampaignStatus.PENDING || status == CampaignStatus.EXTRA;

    const before = status == CampaignStatus.BEFORE;
    const after = status == CampaignStatus.AFTER;

    const bgColor = campaign.theme?.background_color ?? Colors.first;
    const textColor = isDark || campaign.is_background_cover_url_dark ? 'white' : 'black';

    return (
        <>
            {before && (
                <Typography textAlign="center" sx={{color: Colors.firstHover}}>
                    {t('error.before')}
                </Typography>
            )}
            {after && (
                <Typography textAlign="center" sx={{color: Colors.firstHover}}>
                    {t('error.after')}
                </Typography>
            )}
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                sx={{marginTop: '30px', marginBottom: '30px'}}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                        flexDirection: blocDirection,
                        WebkitBoxShadow: Colors.shadow,
                        BowShadow: Colors.shadow,
                        borderRadius: '8px',
                        backgroundColor: campaign.is_background_cover_url_dark
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'rgba(255, 255, 255, 0.4)',
                        color: textColor,
                        backdropFilter: 'blur(10px)',
                    }}
                >
                    <Box display="flex" flexDirection="row" justifyContent="center">
                        {!modeVideo && (
                            <Box style={{position: 'relative'}}>
                                {Boolean(picture) && (
                                    <Box
                                        sx={{
                                            width: {
                                                xs: MOBILE_CARD_WIDTH,
                                                md: LAPTOP_CARD_WIDTH,
                                            },
                                            height: {
                                                xs: MOBILE_CARD_HEIGHT,
                                                md: LAPTOP_CARD_HEIGHT,
                                            },
                                        }}
                                    >
                                        <Image
                                            priority
                                            fill
                                            src={picture}
                                            alt={labels.title}
                                            style={{
                                                objectFit: 'cover',
                                                display: 'block',
                                                borderTopLeftRadius: '8px',
                                                borderBottomLeftRadius:
                                                    isLaptop || isTablet || isMobile
                                                        ? '0px'
                                                        : '8px',
                                                borderTopRightRadius:
                                                    isLaptop || isTablet || isMobile
                                                        ? '8px'
                                                        : '0px',
                                            }}
                                        />
                                    </Box>
                                )}
                                {!Boolean(picture) && (
                                    <HideImageOutlinedIcon
                                        sx={{
                                            width: imgWidth,
                                            height: imgWidth / RATIO_IMAGE,
                                            margin: 'auto',
                                            display: 'block',
                                            borderTopLeftRadius: '8px',
                                            borderBottomLeftRadius:
                                                isLaptop || isTablet || isMobile
                                                    ? '0px'
                                                    : '8px',
                                            borderTopRightRadius:
                                                isLaptop || isTablet || isMobile
                                                    ? '8px'
                                                    : '0px',
                                        }}
                                    />
                                )}
                                {Boolean(labels.video_url) && (
                                    <IconButton
                                        aria-label={t('action.play')}
                                        onClick={play}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                        sx={{
                                            opacity: 0.5,
                                            '&:hover': {opacity: 1},
                                        }}
                                    >
                                        <PlayCircleOutlineIcon
                                            style={{fill: 'white'}}
                                            sx={{
                                                fontSize: '50px',
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                        <Box
                            sx={{
                                height: {
                                    xs: MOBILE_CARD_HEIGHT,
                                    md: LAPTOP_CARD_HEIGHT,
                                },
                            }}
                        >
                            <div id="player"></div>
                        </Box>
                    </Box>
                    <Box
                        padding={'10px'}
                        sx={{
                            borderBottomLeftRadius: {xs: '8px', xl: '0px'},
                            borderTopRightRadius: {xs: '0px', xl: '8px'},
                            borderBottomRightRadius: '8px',
                            backgroundColor: 'none',
                        }}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <LikeButton
                                campaignId={campaign.campaign_id}
                                campaignUrl={campaign.url}
                            />
                        </Box>
                        <Box
                            sx={{
                                marginLeft: {xs: '10px', md: '50px'},
                                marginRight: {xs: '10px', md: '50px'},
                            }}
                            display="flex"
                            flex="1"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexDirection: {xs: 'column', md: 'row'},
                                    marginTop: {xs: 0, md: '10px'},
                                    marginBottom: {xs: 2, md: 0},
                                    gap: {xs: 1, md: 0},
                                }}
                            >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <AuthorAvatarLink author={campaign.author} />
                                    <AuthorLink
                                        author={campaign.author}
                                        forceDark={campaign.is_background_cover_url_dark}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignSelf="center"
                                    alignItems="center"
                                >
                                    <LocalOfferIcon
                                        sx={{
                                            marginRight: '5px',
                                            fontSize: {xs: 12, sm: 28},
                                        }}
                                    />
                                    {campaign.tags.map((t) => (
                                        <TagElement
                                            tag={t.tag}
                                            key={t.tag.tag_id}
                                            sx={{maxWidth: {xs: '250px', md: '150px'}}}
                                        />
                                    ))}
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent={'flex-start'}
                                sx={{alignItems: 'center', alignSelf: 'center', mb: 2, mt: 2}}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <GroupsIcon
                                        sx={{
                                            color: bgColor,
                                            marginRight: '10px',
                                            fontSize: {xs: 30, sm: 35},
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: {xs: 18, sm: 25},
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {campaign.contribution.toString() +
                                            ' ' +
                                            t('campaign.participations', {
                                                count: campaign.contribution,
                                            })}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Savings
                                        sx={{
                                            color: Colors.yellow,
                                            marginRight: '10px',
                                            fontSize: {xs: 20, sm: 25},
                                        }}
                                    />
                                    <Typography>
                                        <Box sx={{fontWeight: 'bold', display: 'inline'}}>
                                            {campaign.amount.toLocaleString('fr-FR')}
                                            {' € '}
                                        </Box>
                                        collectés
                                    </Typography>
                                </Box>
                                {campaign.nb_contributors != null && (
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            cursor: 'pointer',
                                            '&:hover': {textDecoration: 'underline'},
                                        }}
                                        onClick={() => props.showContributions()}
                                    >
                                        {campaign.nb_contributors} {t('label.contributors')}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection={'column'}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                {enabled && (
                                    <Indicator
                                        text={
                                            timeLeft.amount.toString() +
                                            ' ' +
                                            t(timeLeft.type, {count: timeLeft.amount})
                                        }
                                        icon={
                                            <CalendarMonthIcon
                                                sx={{
                                                    alignSelf: 'center',
                                                    color: bgColor,
                                                    marginRight: '10px',
                                                }}
                                            />
                                        }
                                        reverse
                                        left
                                        small={isMobile || isTablet}
                                    />
                                )}
                            </Box>
                            <ImportantButton
                                sx={{
                                    backgroundColor: bgColor,
                                    '&:hover': {
                                        backgroundColor: campaign.theme.hover_color,
                                    },
                                    marginTop: {xs: '15px', xl: '0px'},
                                }}
                                onClick={preorder}
                                disabled={!enabled}
                                startIcon={basketIsEmpty ? null : <ShoppingBagOutlinedIcon />}
                            >
                                {basketIsEmpty ? t('action.preorder') : t('action.buy')}
                            </ImportantButton>
                        </Box>
                        <Share url={campaign.url} title={labels.title} />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

function AuthorAvatarLink(props: {author: AuthorOverview}) {
    const authorPicture = props.author.picture_url;
    const size = {xs: 18, sm: 28};
    return (
        <Link href={'/author/' + props.author.url} style={{textDecoration: 'none'}}>
            <Avatar
                alt={props.author.display_name}
                src={authorPicture}
                imgProps={{referrerPolicy: 'no-referrer'}}
                sx={{
                    width: size,
                    height: size,
                    marginRight: '5px',
                    cursor: 'pointer',
                }}
            />
        </Link>
    );
}

function AuthorLink(props: {author: AuthorOverview; forceDark: boolean}) {
    return (
        <MyLink
            href={'/author/' + props.author.url}
            title={props.author.display_name}
            sx={{maxWidth: {xs: '250px', md: '300px'}}}
            forceDark={props.forceDark}
        />
    );
}
