export function getExpireDate(expireInMinutes: number) {
    const now = new Date();
    let expireTime = new Date(now);
    expireTime.setMinutes(now.getMinutes() + expireInMinutes);
    return expireTime;
}

export async function isExpired(key: string) {
    const value = await localStorage.getItem(key);
    let result = true;
    let time = null;

    if (value !== null) {
        time = new Date(value);

        if (time !== null && time < new Date()) {
            localStorage.removeItem(key);
            time = null;
        } else {
            result = false;
        }
    }

    return result;
}

export async function setExpiration(key: string, expiration: number) {
    const expireAt = getExpireDate(expiration);
    localStorage.setItem(key, expireAt.toString());
}

const NEWS_KEY = 'NEWS_KEY';

export function setLocaleNews(value: number) {
    localStorage.setItem(NEWS_KEY, value.toString());
}

export function getLocaleNews() {
    if (typeof window == 'undefined') {
        return 0;
    }

    const value = localStorage.getItem(NEWS_KEY);
    const n = parseInt(value);
    if (isNaN(n)) {
        return 0;
    } else {
        return n;
    }
}
