import {Box, BoxProps, Link, TextField, Typography, useTheme} from '@mui/material';
import {
    Close,
    Code,
    Facebook,
    LinkOff,
    LinkRounded,
    LinkedIn,
    Twitter,
    WhatsApp,
} from '@mui/icons-material';
import {URL_CLIENT, getShareButtonHost} from '../../utils/settings';
import {useCallback, useState} from 'react';

import {CustomButton} from '../common/button';
import {IconCustom} from '../navigation/logo';
import {Popup} from '../common/modal';
import ShareIcon from '../icons/svg/share.svg';
import {getIframeCode} from '../../utils/iframe';
import {logShare} from '../../utils/tag';
import {useNotification} from '../../provider/NotificationProvider';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useTranslation} from 'next-i18next';

type ShareProps = {
    url: string;
    title: string;
};

export default function Share(props: ShareProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const [hide, setHide] = useState<boolean>(true);
    const modeInverse = isDark ? '_dark' : '_light';
    const {isMobile, isTablet} = useResponsive();
    const [hidingTimeout, setHidingTimeout] = useState<NodeJS.Timeout>();

    const showSharingOptions = useCallback(() => {
        if (hidingTimeout) {
            clearTimeout(hidingTimeout);
        }
        setHide(false);
        const timeout = setTimeout(() => setHide(true), 10_000);
        setHidingTimeout(timeout);
    }, [hidingTimeout]);

    // console.log('hide =', hide);

    if (isMobile || isTablet) {
        return (
            <Box display="flex" flexDirection="row" justifyContent="flex-end" marginTop="25px">
                {hide && (
                    <Box sx={{width: '25px', height: '25px'}} onClick={showSharingOptions}>
                        <ShareIcon />
                    </Box>
                )}
                {!hide && (
                    <Box sx={{gap: 1, display: 'flex', flexDirection: 'row', height: '25px'}}>
                        <ShareIcons {...props} onClose={() => setHide(true)} />
                    </Box>
                )}
            </Box>
        );
    } else {
        return (
            <Box
                display="flex"
                flexDirection="row"
                // justifyContent="flex-end"
                sx={{height: '30px', position: 'relative'}}
            >
                {hide && (
                    <Box
                        sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '0px',
                            bottom: '0px',
                            width: '25px',
                            height: '25px',
                            '&:hover': {color: 'yellow.main'},
                        }}
                        onClick={showSharingOptions}
                    >
                        <ShareIcon />
                    </Box>
                )}
                {!hide && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '0px',
                            top: '-183px',
                            gap: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <ShareIcons {...props} onClose={() => setHide(true)} />
                    </Box>
                )}
            </Box>
        );
    }
}

type ShareIconsProps = {
    url: string;
    title: string;
    onClose?: () => void;
};

export function ShareIcons(props: ShareIconsProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const modeInverse = isDark ? '_dark' : '_light';

    const {t} = useTranslation('common');
    const [integrateOpen, setIntegrateOpen] = useState<boolean>(false);
    const {display} = useNotification();

    const host = getShareButtonHost();
    const miniUrl = URL_CLIENT + '/mini/' + props.url;

    async function copy() {
        (navigator as any).permissions.query({name: 'clipboard-write'}).then((result) => {
            // console.log('result.state = ', result.state);
            if (result.state == 'granted' || result.state == 'prompt') {
                navigator.clipboard.writeText(getIframeCode(miniUrl));
                display(t('display.code_pasted') + ' 🤓');
                props.onClose?.();
            }
        });
    }

    async function copyLink() {
        (navigator as any).permissions.query({name: 'clipboard-write'}).then((result) => {
            // console.log('result.state = ', result.state);
            if (result.state == 'granted' || result.state == 'prompt') {
                navigator.clipboard.writeText(document.location.href);
                display(t('display.link_pasted') + ' 🤓');
                props.onClose?.();
            }
        });
    }

    async function integrate() {
        setIntegrateOpen(true);
    }

    async function facebook() {
        const url =
            'http://www.facebook.com/sharer.php?u=' +
            host +
            document.location.pathname +
            '&p[title]=' +
            props.title;
        window.open(url);
        logShare('Facebook');
        props.onClose?.();
    }

    async function twitter() {
        const url =
            'https://twitter.com/intent/tweet?text=' +
            host +
            document.location.pathname +
            '&hashtags=kunekune';
        window.open(url);
        logShare('Twitter');
        props.onClose?.();
    }

    async function linkedIn() {
        const url =
            'https://www.linkedin.com/sharing/share-offsite/?url=' +
            host +
            document.location.pathname;
        window.open(url);
        logShare('LinkedIn');
        props.onClose?.();
    }

    async function whastapp() {
        const url = 'https://api.whatsapp.com/send?text=' + host + document.location.pathname;
        window.open(url);
        logShare('Whatsapp');
        props.onClose?.();
    }

    const mode = isDark ? '_light' : '_dark';
    return (
        <>
            <IconBox onClick={copyLink}>
                <LinkRounded />
            </IconBox>
            <IconBox onClick={integrate}>
                <Code />
            </IconBox>
            <IconBox onClick={facebook}>
                <Facebook />
            </IconBox>
            <IconBox onClick={twitter}>
                <Twitter />
            </IconBox>
            <IconBox onClick={linkedIn}>
                <LinkedIn />
            </IconBox>
            <IconBox onClick={whastapp}>
                <WhatsApp />
            </IconBox>
            {Boolean(props.onClose) && (
                <IconBox onClick={props.onClose}>
                    <Close />
                </IconBox>
            )}
            <Popup
                open={integrateOpen}
                onClose={() => setIntegrateOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={t('title.integrate')}
            >
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <TextField
                        disabled={true}
                        style={{width: 300}}
                        multiline
                        value={getIframeCode(miniUrl)}
                        sx={{alignSelf: 'center', marginBottom: '10px'}}
                    />
                    <Box textAlign="center" alignSelf="center">
                        <iframe
                            frameBorder="0"
                            height="450px"
                            width="300px"
                            scrolling="on"
                            src={miniUrl}
                        ></iframe>
                    </Box>
                    <CustomButton onClick={copy}>{t('action.copy_code')}</CustomButton>
                </Box>
            </Popup>
        </>
    );
}

function IconBox(props: Omit<BoxProps, 'sx'>) {
    return (
        <Box
            sx={{
                cursor: 'pointer',
                '&:hover': {color: 'yellow.main'},
                borderRadius: 2,
                justifyContent: 'center',
                display: 'flex',
            }}
            {...props}
        />
    );
}
