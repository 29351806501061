import parse, {DOMNode, Element, HTMLReactParserOptions, domToReact} from 'html-react-parser';

import Image from 'next/image';
import Link from 'next/link';
import {useTranslation} from 'next-i18next';

/**
 * This function is used to parse HTML content and convert it to React components.
 *
 * @param options - An object containing options for the parser.
 * @param options.optimizeImages - should images be optimized?
 * (this will replace <img> tags with Next.js <Image> component)
 * @param options.optimiseGif - should GIF images should be optimized?
 * You most likely don't want this to be true, because it costs a lot of money.
 */
export default function useParser(options?: {
    optimizeImages?: boolean;
    optimiseGif?: boolean;
}) {
    const {i18n} = useTranslation();
    const parserOptions: HTMLReactParserOptions = {
        replace(domNode) {
            if (domNode instanceof Element && domNode.attribs) {
                if (domNode.attribs.contenteditable == 'false') {
                    delete domNode.attribs.contenteditable;
                    return domNode;
                }
                if (domNode.name === 'a') {
                    const href = domNode.attribs.href;
                    if (!href) {
                        return;
                    }
                    const isNewsLink = href.includes('tab=news');
                    // const isKuneKuneLink =
                    //     href.includes('kunekune.fr') || !href.startsWith('https://');
                    return (
                        <Link
                            href={href}
                            shallow={isNewsLink}
                            scroll={!isNewsLink}
                            locale={i18n.language}
                            // target={isKuneKuneLink ? '_self' : '_blank'}
                        >
                            {domToReact(domNode.children as DOMNode[], parserOptions)}
                        </Link>
                    );
                }
                if (domNode.name === 'img' && options.optimizeImages) {
                    const imageSrc = domNode.attribs.src;
                    let originalSizeField = domNode.attribs['origin-size'];
                    if (!originalSizeField) {
                        originalSizeField = domNode.attribs['data-file-size'];
                    }
                    if (!originalSizeField) {
                        return;
                    }
                    const originalSize = originalSizeField.split(',');
                    const [originalWidthStr, originalHeightStr] = originalSize;
                    const originalWidth = parseFloat(originalWidthStr);
                    const originalHeight = parseFloat(originalHeightStr);
                    const userDefinedSize = domNode.attribs['data-size']?.split(',');
                    let userDefinedWidth: string | undefined;
                    let userDefinedHeight: string | undefined;
                    if (userDefinedSize) {
                        [userDefinedWidth, userDefinedHeight] = userDefinedSize;
                    }
                    let width = originalWidth;
                    let height = originalHeight;
                    let styleWidth: number | string | undefined = originalWidth;
                    let styleHeight: number | string | undefined;
                    if (userDefinedWidth?.includes('px') && userDefinedHeight === 'auto') {
                        width = parseFloat(userDefinedWidth.split('px')[0]);
                        height = (originalHeight / originalWidth) * width;
                        styleWidth = width;
                        styleHeight = 'auto';
                    } else if (
                        userDefinedHeight?.includes('px') &&
                        userDefinedWidth === 'auto'
                    ) {
                        height = parseFloat(userDefinedHeight.split('px')[0]);
                        width = (originalWidth / originalHeight) * height;
                        styleHeight = height;
                        styleWidth = 'auto';
                    } else if (
                        userDefinedWidth?.includes('px') &&
                        userDefinedHeight?.includes('px')
                    ) {
                        height = parseFloat(userDefinedHeight.split('px')[0]);
                        width = parseFloat(userDefinedWidth.split('px')[0]);
                        styleWidth = width;
                        styleHeight = 'auto'; // height;
                    }
                    if (imageSrc) {
                        return (
                            <Image
                                alt="Illustration image"
                                unoptimized={imageSrc.endsWith('.gif') && !options.optimiseGif}
                                src={imageSrc}
                                width={width}
                                height={height}
                                style={{
                                    maxWidth: '100%',
                                    width: styleWidth,
                                    height: styleHeight,
                                }}
                            />
                        );
                    }
                    return;
                }
            }
        },
    };
    return (html: string) => parse(html, parserOptions);
}
